<template>
  <vx-card class="contained-example-container">
    <div class="header">
      <div class="row">
        <div>
          <h4>{{ title }}</h4>
        </div>
        <div>
          <div class="actions">
            <span @click="download">
              <vs-icon
                v-tooltip="lang.metrics.download[languageSelected]"
                icon="icon-download"
                icon-pack="feather"
                style="cursor: pointer"
              ></vs-icon>
            </span>
            <!-- <metric-dropdown
              :on-last-week="updateInterval"
              :on-last-month="updateInterval"
              :on-range-select="openRangeInterval"
            />-->
          </div>
          <!-- <DatepickerRangePopup ref="datepickerRangePopup" @save="saveRangeInterval" /> -->
        </div>
      </div>
      <div>
        <h6 class="description">{{ description }}</h6>
      </div>
    </div>

    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <vs-table
        max-items="10"
        pagination
        :data="result"
        :noDataText="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th :key="indexth" v-for="(_, indexth) in report._extra.th">
            {{ th(indexth) }}
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].text">{{ data[indextr].text }}</vs-td>
            <vs-td :data="data[indextr].count">{{
              data[indextr].count | duration('humanize')
            }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vx-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
const moment = require('moment')
const Json2csvParser = require('json2csv').Parser

export default {
  name: 'TableDurationV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  data() {
    return {
      Json2csvParser,

      LIMIT: 10, // cantidad de elementos a mostrar
      key: new Date().getTime(),

      // interval: {},
      result: [
        {
          text: 'N/A',
          count: 0
        }
      ]
    }
  },
  watch: {
    services() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    download() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      let fields = this.report._extra.th.map((_, index) => this.th(index))

      let data = []
      this.result.forEach(element => {
        data.push({})
        data[data.length - 1][fields[0]] = element.text
        data[data.length - 1][fields[1]] = this.$moment
          .duration(element.count)
          .humanize(true)
      })

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_metric_${this.title}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    },
    th(index) {
      if (!this.report || !this.report._extra || !this.report._extra.th) {
        return 'N/A'
      }
      if (this.report._extra.th[index]) {
        const found = this.report._extra.th[index].find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'N/A'
    },
    async load(report) {
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        this.result = result.data[0]
        this.key = new Date().getTime() // para actualizar el componente
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>

<style>
.vs-table--tbody {
  z-index: 1 !important;
}
</style>
